define("discourse/plugins/discourse-category-experts/discourse/components/category-experts-settings", ["exports", "@ember/component", "discourse/models/group", "@ember/object", "discourse/lib/ajax"], function (_exports, _component, _group, _object, _ajax) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _obj;
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  var _default = _exports.default = _component.default.extend((_obj = {
    groupIds: null,
    allGroups: null,
    init() {
      this._super(...arguments);
      this.set("groupIds", this.category.custom_fields.category_expert_group_ids ? this.category.custom_fields.category_expert_group_ids.split("|").map(id => parseInt(id, 10)) : []);
      _group.default.findAll().then(groups => {
        this.set("allGroups", groups.filterBy("automatic", false));
      });
      if (this.siteSettings.enable_badges) {
        (0, _ajax.ajax)("/badges.json").then(response => {
          const badgeOptions = [];
          response.badges.forEach(badge => {
            if (badge.enabled) {
              const tempBadge = Object.assign({}, badge);
              tempBadge.id = tempBadge.id.toString();
              badgeOptions.push(tempBadge);
            }
          });
          this.set("badgeOptions", badgeOptions);
        });
      }
    },
    onChangeGroupIds(value) {
      this.set("groupIds", value);
      this.set("category.custom_fields.category_expert_group_ids", value.join("|"));
    },
    onChangeAcceptingExpertEndorsements(value) {
      this.set("category.custom_fields.category_accepting_endorsements", value ? "true" : null);
    },
    onChangeAcceptingExpertQuestions(value) {
      this.set("category.custom_fields.category_accepting_questions", value ? "true" : null);
    }
  }, (_applyDecoratedDescriptor(_obj, "onChangeGroupIds", [_object.action], Object.getOwnPropertyDescriptor(_obj, "onChangeGroupIds"), _obj), _applyDecoratedDescriptor(_obj, "onChangeAcceptingExpertEndorsements", [_object.action], Object.getOwnPropertyDescriptor(_obj, "onChangeAcceptingExpertEndorsements"), _obj), _applyDecoratedDescriptor(_obj, "onChangeAcceptingExpertQuestions", [_object.action], Object.getOwnPropertyDescriptor(_obj, "onChangeAcceptingExpertQuestions"), _obj)), _obj));
});