define("discourse/plugins/discourse-category-experts/discourse/components/endorsement-button", ["exports", "@ember/component", "@ember/object", "discourse-common/utils/decorators", "discourse/lib/show-modal"], function (_exports, _component, _object, _decorators, _showModal) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _obj;
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  var _default = _exports.default = _component.default.extend((_dec = (0, _decorators.default)("user.category_expert_endorsements"), (_obj = {
    tagName: "",
    disabled: true,
    user: null,
    endorsements: null,
    categoriesAllowingEndorsements: null,
    init() {
      this._super(...arguments);
      if (!this.siteSettings.enable_category_experts || !this.currentUser || this.currentUser.id === this.user.id || this.user.suspended) {
        return;
      }
      this.set("categoriesAllowingEndorsements", this.site.categories.filter(c => c.allowingCategoryExpertEndorsements));
      if (this.categoriesAllowingEndorsements.length) {
        this.set("disabled", false);
      }
    },
    endorsements(categoryExpertEndorsements) {
      let category_ids = this.categoriesAllowingEndorsements.map(c => c.id);
      let endorsements = categoryExpertEndorsements.filter(endorsement => {
        return category_ids.includes(endorsement.category_id);
      });
      this.set("endorsementsCount", endorsements.length);
      return endorsements;
    },
    openEndorsementModal() {
      if (this.close) {
        this.close();
      }
      if (this.location) {
        this.appEvents.trigger("category-experts:endorse-clicked", {
          location: this.location,
          user_id: this.currentUser.id,
          endorsed_user_id: this.user.id
        });
      }
      (0, _showModal.default)("endorse-user", {
        model: {
          user: this.user,
          endorsements: this.endorsements,
          location: this.location
        },
        title: "category_experts.manage_endorsements.title"
      });
    }
  }, (_applyDecoratedDescriptor(_obj, "endorsements", [_dec], Object.getOwnPropertyDescriptor(_obj, "endorsements"), _obj), _applyDecoratedDescriptor(_obj, "openEndorsementModal", [_object.action], Object.getOwnPropertyDescriptor(_obj, "openEndorsementModal"), _obj)), _obj)));
});