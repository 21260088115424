define("discourse/plugins/discourse-category-experts/discourse/helpers/category-expert-question-indicator", ["exports", "I18n", "discourse-common/lib/helpers", "@ember/template"], function (_exports, _I18n, _helpers, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.categoryExpertQuestionIndicator = categoryExpertQuestionIndicator;
  function categoryExpertQuestionIndicator(topic, currentUser) {
    if (!currentUser || topic.expert_post_group_names?.length) {
      return;
    }
    if (currentUser.staff || topic.creator && topic.creator.id === currentUser.id || currentUser.expert_for_category_ids.includes(topic.category_id)) {
      return (0, _template.htmlSafe)(`<a href="/search?q=is:category_expert_question" class='topic-list-category-expert-question'>${_I18n.default.t("category_experts.topic_list.question")}</a>`);
    }
  }
  (0, _helpers.registerUnbound)("category-expert-question-indicator", categoryExpertQuestionIndicator);
});