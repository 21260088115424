define("discourse/plugins/discourse-category-experts/discourse/pre-initializers/extend-for-category-experts", ["exports", "discourse/models/composer", "@ember/object/computed", "discourse/lib/plugin-api", "discourse/plugins/discourse-category-experts/discourse/components/modal/expert-group-chooser"], function (_exports, _composer, _computed, _pluginApi, _expertGroupChooser) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    name: "extend-for-category-experts",
    before: "inject-discourse-objects",
    initialize() {
      _composer.default.serializeOnCreate("is_category_expert_question", "is_category_expert_question");
      _composer.default.serializeOnUpdate("is_category_expert_question", "is_category_expert_question");
      (0, _pluginApi.withPluginApi)("0.8.31", api => {
        api.modifyClass("model:category", {
          pluginId: "discourse-category-experts",
          allowingCategoryExpertEndorsements: (0, _computed.and)("custom_fields.category_expert_group_ids", "custom_fields.category_accepting_endorsements"),
          allowingCategoryExpertQuestions: (0, _computed.and)("custom_fields.category_expert_group_ids", "custom_fields.category_accepting_questions")
        });
        api.addPluginReviewableParam("ReviewableCategoryExpertSuggestion", "group_id");
        api.registerReviewableActionModal("approve_category_expert", _expertGroupChooser.default);
      });
    }
  };
});